import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import ReactPlayer from "react-player";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Context maintains theme settings across pages
import { AppContext } from "./AppProvider";

// Assets
import rs6Day from "images/rs6.jpeg";
import rs6Nnight from "images/rs6-night.jpg";
import carsList from "data/cars.json";

// CSS / Themeing
import "./App.css";

function partDisplayWindow(partName, videoLink, videoNotes, productLink) {
  return (
    <Paper elevation={4} sx={{ height: "100%" }}>
      <Card sx={{ height: "90%" }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {partName}
          </Typography>
          <Typography gutterBottom component="div">
            {videoNotes}
          </Typography>
        </CardContent>
        <CardMedia>
          <ReactPlayer url={videoLink} controls={true} width={"100%"} light={true} />
        </CardMedia>
      </Card>
      <Button className="productCardButton" target="_blank" size="small" href={productLink}>
        <Typography>Buy</Typography>
      </Button>
    </Paper>
  );
}

const partsGrid = (parts) => {
  return parts.map(({ name, video, videoNotes, product }) => {
    return (
      <Grid item xs={12} lg={6}>
        {partDisplayWindow(name, video, videoNotes, product)}
      </Grid>
    );
  });
};

function getParts(year, make, model, driveTrim) {
  // Gate to catch if all params not filled
  if (!(make && model && driveTrim)) {
    return null;
  }

  const partsList = carsList[make][model][driveTrim]["parts"];

  return (
    <div>
      {/* ToDo: Add ability to search by part or by video */}
      <Typography variant="h3">
        Videos for {year} {make} {model} {driveTrim}
      </Typography>
      <Container>
        <Grid container spacing={2}>
          {partsGrid(partsList)}
        </Grid>
      </Container>
    </div>
  );
}

function App(props) {
  const { isDarkTheme } = useContext(AppContext);
  const [year, setYear] = React.useState("");
  const [make, setMake] = React.useState("");
  const [model, setModel] = React.useState("");
  const [driveTrim, setDriveTrim] = React.useState("");

  let rs6 = isDarkTheme ? rs6Nnight : rs6Day;

  function vehicleMakeSelectorList(vehicleMakeList) {
    return vehicleMakeList.map((vehicleMake) => (
      <MenuItem key={vehicleMake} value={vehicleMake}>
        {vehicleMake}
      </MenuItem>
    ));
  }

  function vehicleModelSelector(make) {
    //Don't display model selector until make specified
    if (!make)
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="select-model">Model</InputLabel>
        </FormControl>
      );

    // Once make specified, return selector list
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-model">Model*</InputLabel>
        <Select
          labelId="select-model"
          id="s-select-model"
          value={model}
          label="Model *"
          onChange={(event) => {
            setModel(event.target.value);
            setDriveTrim("");
          }}
        >
          {vehicleModelSelectorList(carsList[make])}
        </Select>
      </FormControl>
    );
  }

  function vehicleModelSelectorList(vehicleModelList) {
    // Gate to check if model has been selected yet
    if (!vehicleModelList) return null;

    return Object.keys(vehicleModelList).map((vehicleModel) => (
      <MenuItem key={vehicleModel} value={vehicleModel}>
        {vehicleModel}
      </MenuItem>
    ));
  }

  function vehicleDriveTrimSelector(make, model) {
    //Don't display drive / trim selector until make and model specified
    if (!(make && model))
      return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="select-drive/trim">Drive/Trim</InputLabel>
        </FormControl>
      );

    //Display drive / trim selector once make and model selected
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-drive/trim">Drive/Trim*</InputLabel>
        <Select
          labelId="select-drive/trim"
          id="s-select-drive/trim"
          value={driveTrim}
          label="Trim/Drive *"
          onChange={(event) => setDriveTrim(event.target.value)}
        >
          {vehicleDriveTrimSelectorList(carsList, make, model)}
        </Select>
      </FormControl>
    );
  }

  function vehicleDriveTrimSelectorList(vehicleList, make, model) {
    // Gate to check if model has been selected yet
    if (!(vehicleList && make && model)) return null;
    return Object.keys(vehicleList[make][model]).map((driveTrim) => (
      <MenuItem key={driveTrim} value={driveTrim}>
        {driveTrim}
      </MenuItem>
    ));
  }

  return (
    <HelmetProvider>
      {/* https://matthewshields.co.uk/using-react-helmet-to-manage-document-head-seo-tags#seo-requirements-for-the-document-head */}
      <Helmet>
        <title>Search Exhaust</title>
        {/* Keep description to 50-160 characters */}
        <meta
          name="description"
          content="The most extensive library of aftermarket exhaust audio and videos in the world. Find your car and your ideal exhaust mods today!"
        ></meta>
        <link rel="canonical" href="https://exampledomain.com/" />
      </Helmet>
      <div className="App">
        <img src={rs6} className="headerImage" alt="mainCarImage" />
        <Container className="body" maxWidth="false">
          <Container className="header" maxWidth="md">
            <Typography variant="h2">Search Exhaust Audio</Typography>
            <Typography sx={{ fontStyle: "italic" }}>The most extensive catalog of exhaust audio and video in the world.</Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="select-year">Year</InputLabel>
                <Select
                  labelId="select-year"
                  id="s-select-year"
                  data-testid="select-year"
                  value={year}
                  label="Year"
                  onChange={(event) => setYear(event.target.value)}
                >
                  <MenuItem key={2023} value={2023}>
                    2023
                  </MenuItem>
                  <MenuItem key={2022} value={2022}>
                    2022
                  </MenuItem>
                  <MenuItem key={2021} value={2021}>
                    2021
                  </MenuItem>
                  <MenuItem key={2020} value={2020}>
                    2020
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="select-make">Make*</InputLabel>
                <Select
                  labelId="select-make"
                  data-testid="select-make"
                  id="s-select-make"
                  value={make}
                  label="Make"
                  onChange={(event) => {
                    setMake(event.target.value);
                    setModel("");
                    setDriveTrim("");
                  }}
                >
                  {vehicleMakeSelectorList(Object.keys(carsList))}
                </Select>
              </FormControl>
              {vehicleModelSelector(make)}
              {vehicleDriveTrimSelector(make, model)}
            </Box>
          </Container>
          <Container className="partsDisplay" maxWidth="xl">
            {getParts(year, make, model, driveTrim)}
          </Container>
        </Container>
      </div>
    </HelmetProvider>
  );
}

export default App;
