import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

// ------- Styles -------
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(AppBar, {
  name: "StyledBox",
  slot: "Wrapper",
})({
  paddingTop: "20px",
  paddingBottom: "20px",
  alignItems: "center",
  justifyContent: "center",
});

const FooterBar = () => {
  const [userCount, setUserCount] = useState(false);

  //Special handling to use localhost SAM API if running locally via npm start(make run)
  const apiUrl =
    process.env.NODE_ENV !== "development"
      ? "https://api." + process.env.REACT_APP_DOMAIN + "/users"
      : process.env.REACT_APP_USER_API_URL_LOCAL_SAM;

  async function fetchUserCount() {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setUserCount(response["User count"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Prevent continuous reloading calling API each time
  useEffect(() => {
    fetchUserCount();
  }, []);

  return (
    <StyledAppBar className="FooterBar" id="FooterBar" position="static">
      <Container maxWidth="lg">
        <Typography className="visitorCounter">Visitor Count: {userCount}</Typography>
      </Container>
    </StyledAppBar>
  );
};
export default FooterBar;
