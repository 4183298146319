import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import AppProvider from "./AppProvider";

// Pages
import App from "./App";
import Shop from "./Pages/Shop";
import AddMyCar from "./Pages/AddMyCar";
import AboutUs from "./Pages/AboutUs";
import NotFound from "./Pages/404";

// CSS / Themeing
import "./App.css";
import Theme from "./Theme";
import CssBaseline from "@mui/material/CssBaseline";

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter>
        <Theme>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="shop" element={<Shop />} />
            <Route path="add-my-car" element={<AddMyCar />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Theme>
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
