import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const toggleTheme = () => {
    setIsDarkTheme((prevState) => {
      if (prevState === false) {
        return true;
      } else {
        return false;
      }
    });
  };

  const value = { toggleTheme, isDarkTheme };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
