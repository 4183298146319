import React, { useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto";

// Context maintains theme settings across pages
import { AppContext } from "./AppProvider";

// Components
import Navbar from "components/navBar";
import FooterBar from "components/footerBar";

const Theme = (props) => {
  const { toggleTheme, isDarkTheme } = useContext(AppContext);

  const handleThemeChange = (e) => {
    toggleTheme();
  };

  // Define theme settings
  const lightMode = createTheme({
    palette: {
      mode: "light",
    },
  });

  // https://mui.com/customization/dark-mode/
  const darkMode = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const themes = {
    false: lightMode,
    true: darkMode,
  };

  return (
    <ThemeProvider theme={themes[isDarkTheme]}>
      <Navbar className="AppBar" isDarkTheme={isDarkTheme} handleThemeChange={handleThemeChange} />
      {props.children}
      <FooterBar />
    </ThemeProvider>
  );
};
export default Theme;
