import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Helmet, HelmetProvider } from "react-helmet-async";

function NotFound() {
  return (
    <HelmetProvider>
      {/* https://matthewshields.co.uk/using-react-helmet-to-manage-document-head-seo-tags#seo-requirements-for-the-document-head */}
      <Helmet>
        <title>Page Not Found - Search Exhaust</title>
        <meta name="description" content="404 Page not found" />
      </Helmet>
      <div className="404">
        <Container>
          <Typography variant="h1">404</Typography>
        </Container>
      </div>
    </HelmetProvider>
  );
}

export default NotFound;
